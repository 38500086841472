.sidebar {

  .desktop {
    position: fixed;
    width: 288px;
    //max-height: 982px;
    height: 100%;
    background-color: #071E2F;
    display: flex;
    flex-direction: column;
    z-index: 999;

    .top {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .sidebarLogoContainer {
      //height: 222px;

      .sidebarLogoInner {
        padding: 40px;

        img {
          width: 66px;
          user-select: none;
        }
      }
    }

    .sidebarUserInfo {
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 40px;

      p {
        color: var(--white, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.5;
      }
    }

    .btnContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;

      button {
        padding: 18px 40px;
        color: var(--white, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.5;
        display: flex;
        align-items: center;
        gap: 8px;
        user-select: none;

        svg {
          fill: white;
          user-select: none;
        }
      }

      .activeButton {
        background: #87C2DF;
        color: #071E2F;
        opacity: 1;

        svg {
          fill: #071E2F;
        }
      }


    }

    .logOutContainer {
      margin-top: auto;
      padding: 18px 40px 64px 40px;

      .logOutButton {
        color: white;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.5;
        display: flex;
        align-items: center;
        gap: 8px;
        user-select: none;
      }
    }

  }

  .mobile {
    display: none;
  }

}

@media (max-width: 768px) {
  .sidebar {
    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      //width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 12px 40px;
      background-color: #071E2F;
      z-index: 999;
      .iconsContainer{
        display: flex;
        align-items: center;
        //gap: 24px;
        button{
          width: 48px;
          height: 48px;
          user-select: none;
        }
        img{
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
      }
    }
  }
}

@media (max-width: 428px) {
  .sidebar {
    .mobile{
      padding: 12px 24px;
    }
  }
}
