.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border-radius: 10px;
  z-index: 100;
  border: 1px solid #E3E4E8;
  padding: 10px;
  width: 240px;

  .selectedStatus {
    font-weight: 700;
  }

  .dropdownHeader{
    border-radius: 10px;
    border: 1px solid var(--accent-cta, #87C2DF);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div {
    padding: 10px;
    cursor: pointer;
    color: var(--black, #111);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      background-color: #F5F7F9;
    }
  }
  .statusContainer {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 1px;
    }

    .statusIndicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 10px;
    }

    &.yellow .statusIndicator {
      background-color: #F3BE45;
    }

    &.orange .statusIndicator {
      background-color: #D55E49;
    }

    &.red .statusIndicator {
      background-color: red;
    }

    &.gray .statusIndicator {
      background-color: #CDD0E8;
    }

    &.green .statusIndicator {
      background-color: #09E2B2;
    }

    &.blue .statusIndicator {
      background-color: #3050F9;
    }
  }
}
