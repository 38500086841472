.DataTable {
  //padding: 32px 48px 0 32px;
  position: relative;
  max-height: 982px;
  min-height: 982px;
  margin: auto;
  //max-width: 1400px;
  max-width: 1920px;

  .invoicesWrapper {
    padding: 0px 48px 0 32px;

    .tableWrapper {
      overflow-x: auto;
      white-space: nowrap;
      max-width: 100%;
      margin-top: 24px;
      //padding: 0px 48px 0 32px;


      table {
        border-radius: 5px;
        border: 1px solid #E3E4E8;
        width: 100%;
        border-spacing: 0;
        position: relative;
        background-color: white;

        thead {
          background: #F5F7F9;
          position: relative;

          th {
            cursor: pointer;
            text-align: left;
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 14px;
            padding-left: 32px;
          }
        }

        tbody {
          tr {
            background-color: white;
            cursor: pointer;

            //&:hover {
            //  background-color: #F5F7F9;
            //}

            td {
              font-size: 14px;
              //min-width: 120px;
              padding: 24px 24px 24px 32px;
            }
          }
        }
      }
    }

    .statusContainer {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 1px;
      }

      .statusIndicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 10px;
      }

      &.yellow .statusIndicator {
        background-color: #F3BE45;
      }

      &.orange .statusIndicator {
        background-color: #D55E49;
      }

      &.red .statusIndicator {
        background-color: red;
      }

      &.gray .statusIndicator {
        background-color: #CDD0E8;
      }

      &.green .statusIndicator {
        background-color: #09E2B2;
      }

      &.blue .statusIndicator {
        background-color: #3050F9;
      }
    }

    .paginationContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: 24px;
      user-select: none;

      .activePage {
        border-radius: 7px;
        background: #87C2DF;
        display: flex;
        padding: 5px 9px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--white, #FFF);
      }

      button {
        color: var(--black, #111);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        max-height: 20px;
      }
    }

    .error {
      font-size: 26px;
      margin-top: 10px;
      text-align: center;
      span {
        button {
          font-size: 26px;
          font-weight: 600;
        }
      }
    }

    @media (max-width: 768px) {
      .mobileCardContainer {
        border-radius: 10px;
        border: 1px solid #E3E4E8;
        background: #F5F7F9;
        padding: 16px;
        margin-bottom: 12px;

        .mobileCardContainerTop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 14.5px;

          .ref {
            color: var(--black, #111);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .statusContainer {
            p {
              color: var(--black, #111);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .mobileArrowContainer {
            display: flex;
            gap: 12px;
            width: 100%;

            .downloadButton {
              align-self: flex-start;
              margin-left: auto;

              img {
                width: 18px;
                height: 18px;
              }
            }

            .spinner {
              width: 14px;
              height: 14px;
              margin-left: auto;
            }
          }
        }

        .columns {
          display: flex;
          justify-content: space-between;
          gap: 16px;
          //flex-wrap: wrap;
          //overflow-x: auto;

          .item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            white-space: nowrap;

            .header {
              color: var(--secondary-text, #687083);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .value {
              color: var(--black, #111);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: uppercase;
            }
          }

        }

        .mobileCardContent {
          margin-left: 36px;
          background-color: white;
          border-radius: 10px;
          background: var(--white, #FFF);
          padding: 12px;
          overflow-x: auto;

        }

        .mobileDropDownContent {
          //max-height: 0;
          //opacity: 0;
          //transition: max-height 0.3s ease, opacity 0.3s ease;
          margin-left: 36px;
          border-radius: 10px;
          border: 1px solid #E3E4E8;
          background: #F5FCFF;
          padding: 16px;
          margin-top: 12px;
          overflow-x: auto;
          display: none;
          transition: opacity 0.3s ease;

          &.open {
            //max-height: 2000px; //Should be greater than any possible height
            //opacity: 1;
            //
            //

            display: block;

          }

          .top {
            margin-bottom: 12px;

            p {
              color: var(--black, #111);
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }

          .inner {
            padding: 12px;
          }
        }
      }
    }
  }


  @media (max-width: 428px) {
    .mobileCardContainer {

      .mobileCardContainerTop {


        .ref {

        }

        .statusContainer {
          p {

          }

          .statusIndicator {
            margin-right: 5px;
          }
        }

        .mobileArrowContainer {
        }
      }

      .columns {
        flex-direction: column;


        .item {
          flex-direction: row !important;
          justify-content: space-between;

          .header {

          }

          .value {

          }
        }

      }

      .mobileCardContent {


      }

      .mobileDropDownContent {


        &.open {

        }

        .top {


          p {

          }
        }

        .inner {

        }
      }

    }
  }

}

@media (max-width: 768px) {
  .DataTable {
    //padding: 32px 40px 0 40px;
    .invoicesWrapper {
      padding: 0px 40px 0 40px;
    }
  }
}

@media (max-width:428px){
  .DataTable {
    //padding: 32px 40px 0 40px;
    .invoicesWrapper {
      padding: 0px 24px 0 24px;
    }
  }
}

.dropdownAnimation {
  max-height: 0;
  overflow: auto;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;

  &.open {
    max-height: 2000px; //Should be greater than any possible height
    opacity: 1;

  }
}

//
svg {
  transition: transform 0.3s ease;
  transform-origin: center;

  &.rotated {
    transform: rotate(90deg);
  }
}

//
td.innerTD {
  padding: 12px 24px 24px 24px !important;
  border-bottom: 1px solid #E3E4E8 !important;

}

tr.mobileRow {
  td {
    border-bottom: 1px solid #E3E4E8;

    &.opened {
      border-bottom: 0;
    }
  }
}

table.innerTable {
  thead {
    tr {
      th {
        background: #F5FCFF;
      }
    }
  }

  tbody {
    tr {
      td {
        text-transform: uppercase;
      }
    }
  }
}

.invoiceNumber {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.downloadButton {
  margin-left: 5px;

  img {
    width: 14px;
    height: 14px;
  }
}

.spinner {
  width: 10px;
  height: 10px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



