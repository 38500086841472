.DataTable {
  //padding: 32px 48px 0 32px;
  position: relative;
  max-height: 982px;
  min-height: 982px;
  margin: auto;
  //max-width: 1400px;
  max-width: 1920px;

  .ordersWrapper {
    padding: 0px 48px 0 32px;
    .tableWrapper {
      overflow-x: auto;
      white-space: nowrap;
      max-width: 100%;
      //padding: 0px 48px 0 32px;
    }

    table {
      border-radius: 5px;
      border: 1px solid #E3E4E8;
      width: 100%;
      border-spacing: 0;
      position: relative;
      background-color: white;

      thead {
        background: #F5F7F9;
        position: relative;

        th {
          cursor: pointer;
          text-align: left;
          padding-top: 12px;
          padding-bottom: 12px;
          font-size: 14px;
          padding-left: 32px;
        }
      }

      tbody {
        tr {
          background-color: white;
          cursor: pointer;

          &:hover {
            background-color: #F5F7F9;
          }

          td {
            font-size: 14px;
            //min-width: 120px;
            padding: 24px 24px 24px 32px;
          }
        }
      }
    }


    .statusContainer {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 1px;
      }

      .statusIndicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 10px;
      }

      &.yellow .statusIndicator {
        background-color: #F3BE45;
      }

      &.orange .statusIndicator {
        background-color: #D55E49;
      }

      &.red .statusIndicator {
        background-color: red;
      }

      &.gray .statusIndicator {
        background-color: #CDD0E8;
      }

      &.green .statusIndicator {
        background-color: #09E2B2;
      }

      &.blue .statusIndicator {
        background-color: #3050F9;
      }
    }

    .error {
      font-size: 26px;
      margin-top: 10px;
      text-align: center;
      span {
        button {
          font-size: 26px;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .DataTable {
    //padding: 32px 40px 0;
  }
  .ordersWrapper {
    padding: 0px 40px 0 40px;

    .tableWrapper {
      padding: 0;
    }
  }
}

@media (max-width: 428px) {
  .DataTable {


    .ordersWrapper {
      padding: 0px 24px 0 24px;

      .tableWrapper {
        overflow-x: unset;
        white-space: normal;
        max-width: 100%;
      }

      table {
        border-radius: 5px;
        border: 1px solid #E3E4E8;
        width: 100%;

        position: relative;
        background-color: white;
        margin-top: 24px;
        margin-bottom: 24px;
        border-collapse: collapse;

        overflow-x: auto;
        white-space: normal;


        border-spacing: initial !important;
        background-color: transparent !important;
        border: none !important;


        th {
          display: none;
        }

        thead {
          display: none;
        }

        tbody {
          tr {
            //background-color: white;
            //cursor: pointer;
            //border-bottom: 1px solid #E3E4E8;

            &:hover {
              background-color: white;
            }

            td {
              min-width: auto;
            }

          }
        }

        tbody {
          tr {
            border: 1px solid #E3E4E8;
            padding: 12px;
          }
        }

        tr {
          display: flex;
          flex-direction: column;
          padding: 12px;
          border-radius: 10px !important;
          margin-bottom: 12px;
        }

        tr:last-of-type {
          //margin-bottom: 0px;
        }

        tr.shipment {
          //padding: 16px;
          //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%), linear-gradient(to bottom, rgba(255, 254, 229, 1) 0%, rgba(255, 254, 229, 1) 100%);
          //background-clip: content-box, padding-box;
        }

        td {
          display: flex;
          justify-content: space-between;
          padding: 0 0 12px !important;
          color: var(--black, #111);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          //max-width: 180px;
        }

        td.mobileDescription {
          display: flex;
          flex-direction: column;
          gap: 8px;
          max-width: 240px;
          white-space: normal;
          order: 1;
          margin-top: 24px;
        }

        td:last-of-type {
          //padding-bottom: 0 !important;
        }

        td::before {
          content: attr(data-cell);
          text-transform: capitalize;
          color: var(--secondary-text, #687083);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }


        thead {
          //background: #FFEFE5;
          //position: relative;

          &.shipment {

            background: #FFFEE5;


          }

          th {
            //cursor: pointer;
            //text-align: left;
            ////text-align: center;
            ////padding-top: 12px;
            ////padding-bottom: 12px;
            //font-size: 14px;
            //
            //padding: 12px 12px 12px 32px;
          }
        }

        tbody {
          tr {
            //background-color: white;
            //cursor: pointer;
            //border-bottom: 1px solid #E3E4E8;

            &:hover {
              //background-color: #F5F7F9;
            }

            td {
              //padding-top: 24px;
              //padding-bottom: 24px;
              //font-size: 14px;
              //text-align: center;

              //padding: 12px 12px 12px 32px;
              //
              //min-width: 120px;
            }
          }
        }
      }

      //.testing{
      //  display: flex;
      //  justify-content: space-between;
      //  td{
      //    display: flex;
      //    flex-direction: column;
      //    gap: 8px;
      //  }
      //}

      .mobileRow {
        position: relative;

        &:before {
          width: 100%;
          height: 20px;
          background-color: red;
        }
      }

      td[data-cell="Status"] {
        order: -1;
        //margin-left: 50px;
        align-self: flex-start;

        &:before {
          content: '';
        }

        &:after {
          content: url("../../../assets/images/arrowmobile.svg");

          position: absolute;
          right: 14px;
          top: 10px;

        }
      }

      td[data-cell="PO Number"] {
        align-self: flex-start;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        gap: 8px;
        //max-width: 50px;
        &:before {
          //content: ''
        }

      }

      .testing {
        display: flex;
        flex-direction: column;
        gap: 8px;

        td.TD {

        }
      }


    }
  }
}
