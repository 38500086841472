.topbar {
  //padding-left: 288px;
  height: 48px;
  //width: calc(100% - 288px);
  //position: fixed;
  width: 100%;

  .topbarInner {
    padding: 0 48px 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .topbarSearchContainer {
      opacity: 0.5;
      width: 100%;

      form {
        display: flex;
        gap: 4px;
        max-width: 574px;

        input {
          width: 100%;
          border: none;
          background: none;
          font-size: 18px;
          padding-left: 8px;
          outline: none;
        }

        input::placeholder {
          color: #111;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          user-select: none;
        }
      }

      svg {
        width: 24px;
        user-select: none;
      }
    }

    .topbarProfileContainer {

      img {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        user-select: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .topbar {
    display: none;
  }
}
