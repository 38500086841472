.login-wrapper {
    display: flex;
    min-height: 100vh;
    height: fit-content;
}

.login-image-container {
    flex-basis: 55%;
    width: 55%;
    overflow: hidden;
    position: relative;
}

.login-image-container img {
    display: block;
    min-width: 90%;
    min-height: calc(100vh * 0.6);
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-form-container {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #071E2F;
    padding-bottom: 40px;
}

.form-loader{
    position: absolute;
    bottom: 0;
}

.sign-in{
    max-width: 328px;
    text-align: center;
    color: white;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.login-logo {
    width: 75px;
    margin-bottom: 74px;
}

input {
    font-family: inherit;
}

button {
    font-family: inherit;
}

.back-to-login{
    display: flex;
    justify-content: center;
    align-items:center;
    width: 330px;
    padding: 16px 24px;
    gap: 12px;
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    cursor: pointer;

    background: none;
    outline: inherit;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    width: 100%;
}

.login-form input {
    max-width: 328px;
    width:100%;
    height: 54px;
    padding: 16px 24px;
    border-radius: 5px;
    border: 1px solid #E8E9EC;
    background: #F8F8F8;
    box-sizing: border-box;
}

.login-form input::placeholder {
    color: #C1C2C5;
}

.img-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background-size: cover;
    background: url(../../assets/images/bg.webp) center center;
    background-size: cover;
}

.password-container {
    max-width: 328px;
    width: 100%;
    position: relative;
}

.show-password-btn {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.show-password-btn img {
    width: 24px;
    height: 24px;
}

.login-submit-btn {
    display: flex;
    justify-content: center;
    width: 328px;
    padding: 16px 24px;
    gap: 10px;
    border-radius: 5px;
    border: none;
    background: #D55E49;
    cursor: pointer;
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.spinner {
    display: block;
    width: 14px;
    height: 14px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #D1D1D1;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.user-action-link {
    max-width: 328px;
    text-align: center;
    text-decoration: none;
    color: #C1C2C5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    outline: inherit;
}

.instructions {
    cursor: auto;
    padding: 12px 0 40px 0;
}

.forgot-password-p {
    color: #C1C2C5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1em;
    margin-bottom: 1em;
}

.text-return-to-login {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-return-to-login-btn {
    text-decoration: underline;
    font-weight: 400;
    color: #FFFFFF;
    padding: 0 5px;
}

.error-message-container {
    height: 20px;
    margin-top: -10px;
}

.error-message {
    color: red;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.error-message-placeholder {
    visibility: hidden;
}

.invoice-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 328px;
    width: 100%;
    gap: 12px;

    & > input:last-child {
        max-width: 111px;
    }
}

.invoice-label-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.invoice-label-group label {
    color: #FFFFFF;
    font-size: 18px;
}


@media (max-width: 768px) {

    .login-image-container {
        display: none;
    }

    .login-form-container {
        flex-basis: 100%;
        padding: 40px;
    }

    .login-form .user-action-link {
        max-width: 508px;
    }

    .login-form .password-container {
        max-width: 508px;
    }

    .invoice-row {
        max-width: 508px;
    }

    .invoice-label-group {
        max-width: 385px;
        width: calc(100% - 111px);
    }

    .sign-in {
        max-width: 508px;
        margin-bottom: 6px;
    }

    .login-submit-btn {
        max-width: 508px;
        width: 100%;
        margin-top: 6px;
    }

    .back-to-login {
        max-width: 508px;
        width: 100%;
    }

    .login-form input {
        max-width: 508px;

        &:not(last-child) {
            margin: -6px 0;
        }
    }
}

@media (max-width: 428px) {

    .login-form-container {
        padding: 24px;
    }
}


