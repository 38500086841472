.MobileMenu{
  display: none;
}
@media (max-width: 768px) {
  .MobileMenu {
    display: none;
    background-color: #071E2F;
    position: fixed;
    left: 0;
    margin-top: -1px;
    width: 100%;
    height: 0;
    z-index: 999999;
    //transition: height 0.3s ease-in-out;

    &.open {
      height: 100%;
      display: block;
    }



    .MobileMenuItems {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding-left: 40px;
      padding-right: 40px;
      margin-top: 72px;

      .MobileMenuCustomerInfo{
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 28px;
        p{
          color: var(--white, #FFF);
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .MobileMenuItem {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: var(--white, #FFF);
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

  }
}

@media (max-width: 428px) {
  .MobileMenu {


    &.open {

    }

    .MobileMenuItems {

      padding-left: 24px;
      padding-right: 24px;


      .MobileMenuItem {


        p {

        }
      }
    }

  }
}

