.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 24px;
  padding-bottom: 24px;
  user-select: none;

  .activePage {
    border-radius: 7px;
    background: #87C2DF;
    display: flex;
    padding: 5px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white, #FFF);
  }

  button {
    color: var(--black, #111);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-height: 20px;
  }
}
