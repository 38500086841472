.layout{
  .content{
    padding-left: 288px;
  }
}

@media (max-width: 768px){
  .layout{
    .content{
      padding-left: 0px;
    }
  }
}
