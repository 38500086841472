.MobileSearchBar {
  display: none;
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 768px) {
  .MobileSearchBar {
    display: none;
    background-color: #071E2F;
    position: fixed;
    left: 0;
    margin-top: -1px;
    width: 100%;
    z-index: 999999;

    &.open {
      display: block;
    }

    form {
      padding: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .inputWrapper{
        position: relative;
        width: 100%;
      }

      input {
        width: 100%;
        box-sizing: border-box;
        padding: 16px 24px;
        border-radius: 5px;
        border: 1px solid #E8E9EC;
        background: #F8F8F8;
        font-size: 18px;
      }

      input::placeholder{
        color: #C1C2C5;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      button{
        position: absolute;
        right: 24px;
        top: 3px;
        bottom: 3px;
        line-height: 1 !important;
        z-index: 4;
      }



    }

  }
}
