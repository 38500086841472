.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #87C2DF;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: spin 1s linear infinite;

  margin: 100px auto auto;

  //position: absolute;
  //top: 25%;
  //left: 600px;  /* Adjusted to center considering the sidebar */
  //transform: translate(-50%, -25%);

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
