.ordersSingle {
  //padding: 32px 48px 0 32px;
  position: relative;
  //max-height: 982px;
  min-height: 982px;
  margin: auto;
  //max-width: 1400px;
  max-width: 1920px;


  .singleOrdersHeadContainer {
    background-color: #071E2F;
    width: 100%;
    //border-top-left-radius: 15px;
    //border-top-right-radius: 15px;

    .innerContent {
      padding: 24px 32px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .buttonContainer {
        width: fit-content;

        button {
          color: white;
          font-size: 18px;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      .statusContainer {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 1px;
          color: var(--white, #FFF);
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .statusIndicator {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 10px;
        }

        &.yellow .statusIndicator {
          background-color: #F3BE45;
        }

        &.orange .statusIndicator {
          background-color: #D55E49;
        }

        &.red .statusIndicator {
          background-color: red;
        }

        &.gray .statusIndicator {
          background-color: #CDD0E8;
        }

        &.green .statusIndicator {
          background-color: #09E2B2;
        }

        &.blue .statusIndicator {
          background-color: #3050F9;
        }
      }

      .top {
        display: flex;
        justify-content: space-between;

        h2 {
          color: var(--white, #FFF);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .bot {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        color: var(--white, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .calendarContainer {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        p {
          opacity: 0.5;
          white-space: nowrap;
        }

        .accented {
          margin-left: 5px;
          color: var(--accent-2, #D55E49);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration: none;
        }
      }
    }
  }

  .ordersSingleWrapper {
    padding: 40px 48px 0 32px;

    .orderTabs {
      display: flex;
      gap: 1px;
      cursor: pointer;
      width: fit-content;
      max-width: 100%;

      .tab {
        width: 240px;
        padding: 14px 32px;
        border-radius: 15px 15px 0px 0px;
        background: rgba(7, 30, 47, 0.5);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--White, #FFF);
      }

      .activeTab {
        background: rgba(7, 30, 47, 1);

      }
    }


    .backContainer {
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--black, #111);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }

    .orderDetailsContainer {
      padding: 12px 32px;
      background-color: white;
      border: 2px solid #E3E4E8;
      transition: background-color 0.3s ease-in-out;

      &.shipment {
        border-bottom: 2px solid #F5F7F9;
      }

      &:last-of-type {
        border-bottom: 2px solid #E3E4E8;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      &.toggled {
        background-color: #F5F7F9;
      }

      .dropdownOuter {
        .dropdownInner {
          display: flex;
          padding-top: 12px;
          padding-bottom: 12px;

          &.shipment {
            padding: 0;
            align-items: center;
          }

          button {
            width: 64px;
            align-self: flex-start;

            &.shipment {
              align-self: center;
              margin-top: 5px;
            }

            svg {
              transition: transform 0.3s ease;
              transform-origin: center;

              &.rotated {
                transform: rotate(90deg);
              }
            }
          }

          .contents {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            margin-left: 24px;
            margin-right: 24px;
            width: 85%;

            &.shipment {
              width: 100%;
            }


            p {
              color: var(--secondary-text, #687083);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              span {
                color: var(--black, #111);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }

              span.uppercase {
                text-transform: uppercase;
              }
            }
          }

          .trackingInfo {
            display: flex;
            padding: 14px 24px;
            border-radius: 100px;
            background: var(--accent-2, #D55E49);
            text-align: center;
            white-space: nowrap;
            text-decoration: none;

            color: var(--white, #FFF);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
          }

          .statusContainer {
            display: flex;
            align-items: center;
            align-self: baseline;

            p {
              margin-bottom: 1px;
              color: var(--black, #111);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              white-space: nowrap;
            }

            .statusIndicator {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              margin-right: 10px;
            }

            &.yellow .statusIndicator {
              background-color: #F3BE45;
            }

            &.orange .statusIndicator {
              background-color: #D55E49;
            }

            &.red .statusIndicator {
              background-color: red;
            }

            &.gray .statusIndicator {
              background-color: #CDD0E8;
            }

            &.green .statusIndicator {
              background-color: #09E2B2;
            }

            &.blue .statusIndicator {
              background-color: #3050F9;
            }
          }
        }

        .dropdownAnimation {
          max-height: 0;
          overflow: auto;
          opacity: 0;
          transition: max-height 0.3s ease, opacity 0.3s ease;

          &.open {
            max-height: 2000px; //Should be greater than any possible height
            opacity: 1;
          }

          .statusContainer {
            display: flex;
            align-items: center;

            .statusIndicator {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 8px;
            }

            &.yellow .statusIndicator {
              background-color: #F3BE45;
            }

            &.orange .statusIndicator {
              background-color: #D55E49;
            }

            &.red .statusIndicator {
              background-color: red;
            }

            &.gray .statusIndicator {
              background-color: #CDD0E8;
            }

            &.green .statusIndicator {
              background-color: #09E2B2;
            }

            &.blue .statusIndicator {
              background-color: #3050F9;
            }
          }
        }


        table {
          border-radius: 5px;
          border: 1px solid #E3E4E8;
          width: 100%;
          border-spacing: 0;
          position: relative;
          background-color: white;
          margin-top: 24px;
          margin-bottom: 24px;
          border-collapse: collapse;

          overflow-x: auto;
          white-space: nowrap;


          thead {
            background: #FFEFE5;
            position: relative;

            &.shipment {

              background: #FFFEE5;

            }

            th {
              cursor: pointer;
              text-align: left;
              //text-align: center;
              padding-top: 12px;
              padding-bottom: 12px;
              font-size: 14px;

              padding-left: 32px;
            }
          }

          tbody {
            tr {
              background-color: white;
              cursor: pointer;
              border-bottom: 1px solid #E3E4E8;

              &:hover {
                background-color: #F5F7F9;
              }

              td {
                padding-top: 24px;
                padding-bottom: 24px;
                font-size: 14px;
                //text-align: center;

                padding-left: 32px;

                min-width: 200px;
              }
            }
          }
        }


      }
    }

    .shipmentsContainer {
      //margin-top: 24px;
      margin-bottom: 24px;

      .roundedCorners {
        //border-bottom-right-radius: 15px;
        //border-bottom-left-radius: 15px;
      }

      .shipmentsHeader {
        padding: 24px 32px;
        background: #F5FCFF;
        border: 1px solid #E3E4E8;
        border-bottom: 2px solid #E3E4E8;
        //border-top-left-radius: 15px;
        //border-top-right-radius: 15px;

        h1 {
          color: var(--primary, #071E2F);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .ordersSingle {
    //padding: 32px 40px 64px;

    .singleOrdersHeadContainer {
      .innerContent {
        .top {
          h2 {
            font-size: 18px;
          }

          .statusContainer {
            p {
              font-size: 18px;
            }
          }
        }

        .bot {

          p {

          }

          svg.calendar {
            min-width: 16px;
          }

          svg.divider {
            min-width: 2px;
          }

        }
      }
    }

    .ordersSingleWrapper {
      padding: 32px 40px 40px;

      .orderTabs {

        .tab {
          width: auto;
          padding: 10px 32px;
          font-size: 14px;
        }

        .activeTab {

        }
      }

      .backContainer {
        margin-bottom: 32px;
      }

      .orderDetailsContainer {
        position: relative;


        &.shipment {

        }

        &:last-of-type {

        }

        &.toggled {

        }

        .dropdownOuter {
          .dropdownInner {
            flex-direction: column;
            padding-top: 8px;

            &.shipment {
              flex-direction: row;

              p {
                display: flex;
                flex-direction: column;
                gap: 8px;
              }
            }

            button {
              position: absolute;
              left: 5px;
              width: 42px;

              &.shipment {
                align-self: flex-start;
                margin-top: 10px;
              }

              svg {


                &.rotated {

                }
              }
            }

            .contents {

              margin-left: 18px;

              &.shipment {

              }


              p {
                display: flex;
                flex-direction: column;
                gap: 8px;


                span {

                }

                span.uppercase {

                }
              }
            }

            .trackingInfo {

            }

            .statusContainer {
              order: -1;
              margin-left: 18px;
              margin-bottom: 24px;
              margin-top: 4px;

              p {

              }

              .statusIndicator {

              }

            }
          }

          .dropdownAnimation {


            &.open {

            }
          }


          table {
            border-radius: 5px;
            border: 1px solid #E3E4E8;
            width: 100%;
            border-spacing: 0;
            position: relative;
            background-color: white;
            margin-top: 24px;
            margin-bottom: 24px;
            border-collapse: collapse;

            overflow-x: auto;
            white-space: nowrap;


            thead {
              background: #FFEFE5;
              position: relative;

              &.shipment {

                background: #FFFEE5;

              }

              th {
                cursor: pointer;
                text-align: left;
                //text-align: center;
                //padding-top: 12px;
                //padding-bottom: 12px;
                font-size: 14px;

                padding: 12px 12px 12px 32px;
              }
            }

            tbody {
              tr {
                background-color: white;
                cursor: pointer;
                border-bottom: 1px solid #E3E4E8;

                &:hover {
                  background-color: #F5F7F9;
                }

                td {
                  //padding-top: 24px;
                  //padding-bottom: 24px;
                  font-size: 14px;
                  //text-align: center;

                  padding: 12px 12px 12px 32px;

                  min-width: 120px;
                }
              }
            }
          }


        }
      }

      .shipmentsHeader {
        padding: 24px;
        background: #F5FCFF;
        border: 1px solid #E3E4E8;
        border-bottom: 2px solid #E3E4E8;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin-top: 40px;

        h1 {
          color: var(--primary, #071E2F);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

}

@media (max-width: 428px) {
  .ordersSingle {
    //padding: 24px 24px 40px;

    .singleOrdersHeadContainer {
      .innerContent {
        gap: 12px;
        padding: 24px;

        .top {
          h2 {
            font-size: 18px;
          }

          .statusContainer {
            p {
              font-size: 18px;
            }
          }
        }

        .bot {
          flex-direction: column;
          align-items: stretch;

          p {

            opacity: 1;

          }

          .accented {
            margin-left: 0;
          }

          svg.calendar {
            min-width: 16px;
          }

          svg.divider {
            display: none;
          }

        }
      }
    }

    .ordersSingleWrapper {
      padding: 16px 24px 24px;

      .orderTabs {
        width: 100%;

        .tab {
          width: 50%;
        }

        .activeTab {

        }
      }

      .backContainer {
        margin-bottom: 24px;
      }

      .orderDetailsContainer {
        position: relative;


        &.shipment {

          border-top: none;
          border-bottom: none;

        }

        &:last-of-type {

          border-bottom: 2px solid #E3E4E8;
        }


        &.toggled {
          background: #FAFCFE;
          padding-bottom: 0px;
        }

        .dropdownOuter {
          .dropdownInner {
            flex-direction: column;
            padding-top: 8px;

            &.shipment {
              flex-direction: column;
              gap: 24px;
              align-items: stretch;

              p {
                display: flex;
                flex-direction: column;
                gap: 8px;
              }
            }

            button {
              position: absolute;
              left: 5px;
              width: 42px;

              &.shipment {

              }

              svg {


                &.rotated {

                }
              }
            }

            .contents {

              margin-left: 18px;

              &.shipment {

              }


              p {
                display: flex;
                flex-direction: column;
                gap: 8px;


                span {

                }

                span.uppercase {

                }
              }
            }

            .trackingInfo {
              justify-content: center;
              margin-left: 18px;
              margin-right: 18px;
            }

            .statusContainer {
              order: -1;
              margin-left: 18px;
              margin-bottom: 24px;
              margin-top: 4px;

              p {

              }

              .statusIndicator {

              }

            }
          }

          .dropdownAnimation {


            &.open {

            }
          }


          table {
            border-radius: 5px;
            border: 1px solid #E3E4E8;
            width: 100%;

            position: relative;
            background-color: white;
            margin-top: 24px;
            margin-bottom: 24px;
            border-collapse: collapse;

            overflow-x: auto;
            white-space: nowrap;


            border-spacing: initial !important;
            background-color: transparent !important;
            border: none !important;


            th {
              display: none;
            }

            thead {
              display: none;
            }

            tbody {
              tr {
                //background-color: white;
                //cursor: pointer;
                //border-bottom: 1px solid #E3E4E8;

                &:hover {
                  background-color: white;
                }

                td {
                  min-width: auto;
                }

              }
            }

            tbody {
              tr {
                border: 1px solid #E3E4E8;
                padding: 12px;
              }
            }

            tr {
              display: flex;
              flex-direction: column;
              padding: 12px;
              border-radius: 10px !important;
              margin-bottom: 12px;
            }

            tr:last-of-type {
              margin-bottom: 0px;
            }

            tr.shipment {
              //padding: 16px;
              //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%), linear-gradient(to bottom, rgba(255, 254, 229, 1) 0%, rgba(255, 254, 229, 1) 100%);
              //background-clip: content-box, padding-box;
            }

            td {
              display: flex;
              justify-content: space-between;
              padding: 0 0 12px !important;
              color: var(--black, #111);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              //max-width: 180px;
            }

            td.mobileDescription {
              display: flex;
              flex-direction: column;
              gap: 8px;
              max-width: 240px;
              white-space: normal;
              order: 1;
              margin-top: 24px;
            }

            td:last-of-type {
              padding-bottom: 0 !important;
            }

            td::before {
              content: attr(data-cell);
              text-transform: capitalize;
              color: var(--secondary-text, #687083);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }


            thead {
              //background: #FFEFE5;
              //position: relative;

              &.shipment {

                background: #FFFEE5;


              }

              th {
                //cursor: pointer;
                //text-align: left;
                ////text-align: center;
                ////padding-top: 12px;
                ////padding-bottom: 12px;
                //font-size: 14px;
                //
                //padding: 12px 12px 12px 32px;
              }
            }

            tbody {
              tr {
                //background-color: white;
                //cursor: pointer;
                //border-bottom: 1px solid #E3E4E8;

                &:hover {
                  //background-color: #F5F7F9;
                }

                td {
                  //padding-top: 24px;
                  //padding-bottom: 24px;
                  //font-size: 14px;
                  //text-align: center;

                  //padding: 12px 12px 12px 32px;
                  //
                  //min-width: 120px;
                }
              }
            }
          }

        }
      }
    }

    .shipmentsHeader {
      padding: 24px;
      background: #F5FCFF;
      border: 1px solid #E3E4E8;
      border-bottom: 2px solid #E3E4E8;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-top: 32px;

      h1 {
        color: var(--primary, #071E2F);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

