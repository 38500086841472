.agingbar {
  //padding-left: 288px;
  height: 40px;
  //width: calc(100% - 288px);
  width: 100%;
  //position: fixed;
  top: 48px;
  background-color: #D55E49;
  animation: slideDown 0.25s ease forwards;

  .agingbarInner {
    padding: 0 48px 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    p {
      color: var(--white, #FFF);
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
    }

    .agingbarProfileContainer {
      button {
        color: var(--white, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }
}

@media (max-width: 768px) {
  .agingbar {
    padding-left: 0;
    width: 100%;
    height: 100%;

    .agingbarInner {
      padding: 12px 40px;
      height: 100%;
    }
  }
}

@media (max-width: 428px) {
  .agingbar {

    .agingbarInner {
      padding: 12px 24px;
    }
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}

