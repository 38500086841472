@import url('https://fonts.cdnfonts.com/css/industry-test');

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Industry Test', sans-serif;
  box-sizing: border-box;
  background: #FAFCFE;
}

button {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

//::-webkit-scrollbar {
//  -webkit-appearance: none;
//  width: 2px;
//}
//
//::-webkit-scrollbar-thumb {
//  border-radius: 2px;
//  background-color: rgba(0, 0, 0, .5);
//  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
//}
