.orderTableNav {

  .orderTableNavBarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
    flex-wrap: wrap;
    gap: 24px;
    padding: 16px 48px 0 32px;
    padding-left: 0px;
    padding-right: 0px;


    .textContainer {
      display: flex;
      gap: 12px;

      h1 {
        color: #111;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        white-space: nowrap;
      }

      span {
        color: #111;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .filtersContainer {
      display: flex;
      align-items: center;
      margin-top: 4px;

      .textBtnContainer {
        display: flex;
        align-items: center;

        button {
          display: flex;
          //max-width: 100px;
          width: 100px;
          padding: 8px 24px;
          border-radius: 100px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          color: #111;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          opacity: 0.5;
          &.toggled{
            background: var(--accent-cta, #87C2DF);
            color: white;
            opacity: 1;
          }
        }
      }

      .filterBtn {
        margin-left: 32px;
      }
    }
  }
}

@media (max-width: 768px) {
  .orderTableNav {

    .orderTableNavBarContainer {
      margin-bottom: 32px;
      flex-wrap: wrap;
      padding: 16px 0px 0px;

      .textContainer {

        h1 {

        }

        span {

        }
      }

      .filtersContainer {


        .textBtnContainer {


          button {

          }
        }

        .filterBtn {
          margin-left: 12px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 428px) {
  .orderTableNav {

    .orderTableNavBarContainer {
      margin-bottom: 0px;
      flex-direction: column;
      gap: 24px;

      .textContainer {
        align-self: flex-start;

        h1 {
          font-size: 24px;
        }

        span {
          font-size: 24px;
        }
      }

      .filtersContainer {
        width: 100%;

        .textBtnContainer {
          justify-content: space-between;
          width: 100%;

          button {

          }
        }

        .filterBtn {
          position: absolute;
          right: 0;
          //top: 7px;
          top: 20px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
